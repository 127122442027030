@import 'bootstrap/dist/css/bootstrap.min.css';

.App {
    text-align: center;
}

.leaflet-top {
    top: 11px;
}

.leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

}

.leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

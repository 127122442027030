.custom-toast {
  background-color: #fff; 
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.custom-toast::before{
    content: '';
    position: absolute;
    background-image: url(../../../public/logo512.png);
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 4px 0 0 4px;
}